import { Membership } from './enums/membership-type'

export const config = {
  environment: process.env.NEXT_PUBLIC_APP_ENV!,

  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL!,
  appURL: process.env.NEXT_PUBLIC_APP_BASE_URL!,
  graphQlURL: process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL!,

  cookies: {
    isMobileApp: 'is-mobile-app',
    safeAreaInsets: 'safeAreaInsets',
    cookieConsent: 'cookieConsent',
    accountLinkCorrelationId: 'account_link_correlation_id',
  },

  honeypotFieldName: process.env.NEXT_PUBLIC_HONEYPOT_FIELD_NAME!,
  awsBucket: process.env.NEXT_PUBLIC_AWS_BUCKET!,

  enableImageLoader: process.env.NEXT_PUBLIC_APP_ENV === 'production',

  displayAppURL: 'amaselections.com',
  whitelabelURL: process.env.NEXT_PUBLIC_WHITELABEL_BASE_URL!,
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
  amaSelectionsPhoneNumber: '+33497067239',
  amaSelectionsDisplayPhoneNumber: '+33 4 97 06 72 39',
  amaSelectionsEmail: 'info@amaselections.com',
  partnerInquiryFormLink: process.env.NEXT_PUBLIC_PARTNER_INQUIRIES_LINK!,
  travelAgentInquiryFormLink: process.env.NEXT_PUBLIC_TRAVEL_AGENT_INQUIRIES_LINK!,

  stripePercentageCharge: '3.64',
  stripeDashboardUrl: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!.includes('test')
    ? 'https://dashboard.stripe.com/test'
    : 'https://dashboard.stripe.com',
  stripePassportUploadDays: 7,

  bank: {
    EUR: {
      name: 'Societe Generale',
      accountName: 'A.M.A Selections',
      iban: 'FR76 3000 3004 9500 0208 0147 242',
      bic: 'SOGEFRPP',
      addressLine1: '809 Boulevard des Écureuils',
      addressLine2: '06210 Mandelieu-la-Napoule',
      addressLine3: 'France',
    },
    CHF: {
      name: 'Revolut Bank UAB',
      accountName: 'A.M.A Selections',
      iban: 'FR76 2823 3000 0109 1817 1339 303',
      bic: 'REVOFRP2',
      addressLine1: '10 avenue Kléber',
      addressLine2: '75116 Paris',
      addressLine3: 'France',
    },
    USD: {
      name: 'Revolut Bank UAB',
      accountName: 'A.M.A Selections',
      iban: 'FR76 2823 3000 0109 1817 1339 303',
      bic: 'REVOFRP2',
      addressLine1: '10 avenue Kléber',
      addressLine2: '75116 Paris',
      addressLine3: 'France',
    },
  },

  company: {
    name: 'A.M.A Selections SAS',
    address_line_1: '14 Rue Merle',
    address_line_2: '06400 Cannes',
    address_line_3: 'France',
    vat_id: 'FR43794870261',
  },

  stripePK: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!,

  pusher: {
    appKey: process.env.NEXT_PUBLIC_PUSHER_APP_KEY!,
    cluster: 'eu',
  },

  recaptchaSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  siteName: 'A.M.A Selections Holiday Villas',
  acceptedImageTypes: 'image/jpg,image/jpeg,image/bmp,image/png,image/webp',
  acceptedFileTypes: 'application/pdf,image/jpeg,image/png,image/webp,video/mp4,application/vnd.openxmlformats-officedocument.wordprocessingml.document,doc,docx,application/applefil',
  acceptedInvoiceFileTypes: 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,doc,docx,application/applefil',
  wishlistCharacterLimit: 50,
  wishlistCreationLimit: 50,
  homepageVideoHLSUrl: process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL
    ? process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL + '/manifest/video.m3u8'
    : null,
  homepageVideoDashUrl: process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL
    ? process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL + '/manifest/video.mpd'
    : null,
  homepageVideoThumbnailURL: process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL
    ? process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL + '/thumbnails/thumbnail.jpg?height=1400'
    : null,

  // Registration
  bookingChannelOptions: [
    { value: 'airbnb', label: 'Airbnb / VRBO' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'google', label: 'Google Search' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'marriott', label: 'Marriott' },
    { value: 'press', label: 'Press' },
    { value: 'referral', label: 'Referral' },
  ],

  // Commission
  vatPercentage: 20,
  totalCommissionPercentageThreshold: 20,
  maximumAgentCommission: 10,

  forbesLink: 'https://www.forbes.com/sites/irenelevine/2022/06/28/ama-selections-carves-niche-in-short-term-luxury-rental-market/',
  telegraphLink: 'https://www.telegraph.co.uk/travel/travel-truths/private-ski-butlers-on-site-nightclubs-how-super-rich-do-airbnb/',

  userDashboard: {
    publicUrls: [
      '/search',
    ],
  },

  membership: {
    [Membership.REGISTERED]: {
      features: null,
    },
    [Membership.CLASSIC]: {
      features: [
        'Access to exclusive offers',
        '1% loyalty credit earned on every stay',
      ],
    },
    [Membership.SILVER]: {
      description: '1% Loyalty credit earned on every stay.<br />10 reservations per booking (250€ value).<br />Zero concierge service fees.',
      yearlyPrice: 500,
      features: [
        'Access to exclusive offers',
        '1% Loyalty credit earned on every stay',
        '10 reservations per booking (250€ value)',
        'Zero concierge service fees',
      ],
    },
    [Membership.GOLD]: {
      description: '2% Loyalty credit earned on every stay.<br />10 reservations per booking (250€ value).<br />Zero concierge service fees.<br />2000€ accidental damage waiver included.',
      yearlyPrice: 1000,
      features: [
        'Access to exclusive offers',
        '2% Loyalty credit earned on every stay',
        '10 reservations per booking (250€ value)',
        'Zero concierge service fees',
        '2000€ accidental damage waiver included',
      ],
      extraSavings: 19500, // 195 €
    },
  },

  services: {
    service: {
      id: 1,
      searchTerm: 'Service',
    },
    experience: {
      id: 2,
      searchTerm: 'Experience',
    },
  },

  mobileStore: {
    ios: 'https://apps.apple.com/us/app/a-m-a-selections/id6504112783',
    android: 'https://play.google.com/store/apps/details?id=com.amaselections.mobile',
  },
}
