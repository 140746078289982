import { AxiosInstance } from 'axios'
import { InquiryType, RestInquiry } from 'data/types/inquiry'
import { RestProperty } from 'data/types/property'
import { RestUser } from 'data/types/user'

export default class InquiriesApiClient {
  http: AxiosInstance

  constructor (http: AxiosInstance) {
    this.http = http
  }

  async createInquiry (
    data: CreateInquiryData,
    type: InquiryType,
  ) {
    const result = await this.http.post(`/inquiries/${type}`, data)
    return result.data.data
  }

  async assignBookingAgent (data: {
    id: RestInquiry['id']
    agent_id: RestUser['id']
  }) {
    const result = await this.http.post(`/inquiries/${data.id}/assign_booking_agent`, data)
    return result.data.data
  }

  async showInquiry (id: RestInquiry['id']) {
    const result = await this.http.get('/inquiries/' + id)
    return result.data.data
  }

  async getInquiries (
    filters: GetInquiriesFilters = {},
    options: GetInquiriesOptions = {},
  ) {
    const result = await this.http.get('/inquiries', {
      params: {
        ...filters,
        arrival_date: filters.arrival_date,
        booking_date: filters.booking_date,
        departure_date: filters.departure_date,
        per_page: options.per_page,
        page: options.page ?? 1,
        paginated: options.paginated,
        limit: options.limit,
      },
    })
    return {
      inquiries: result.data.data,
      meta: result.data.meta ?? null,
    }
  }

  async deleteInquiry (id: RestInquiry['id']) {
    await this.http.delete(`/inquiries/${id}`)
  }
}

type GetInquiriesFilters = {
  new_inquiries?: boolean
  property_id?: RestProperty['id']
  booking_date?: string
  arrival_date?: string
  departure_date?: string
  search?: string
}

type GetInquiriesOptions = {
  per_page?: number
  page?: number
  paginated?: boolean
  limit?: number
}

type CreateInquiryData = {
  message?: string
} & (
  {
    // Booking Request
    arrival?: string | null
    departure?: string | null
    adults?: number
    children?: number
    property_id?: string
    quoted_price?: number
  } | {
    // General
    arrival?: string | null
    departure?: string | null
    guests?: string
    budget?: string
    destination?: string
    destination_id?: string
  } | {
    // Callback
    first_name?: string
    last_name?: string
    phone_number?: string
    country_code?: string
    recaptcha_token?: string
    honeypot?: string
  }
)
